.interior-solutions {
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #ffffff;
}

.interior-solutions__title {
  font-size: 52px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  font-family: "Inter", sans-serif;
  color: #0f051d;
}

.interior-solutions__highlight {
  color: #d4af37;
}

.interior-solutions__grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.product-card {
  max-width: 261px;
  padding: 1rem;
  /* text-align: center; */
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card__image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.product-card__title {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: justify;
  margin-bottom: 0.5rem;
  color: #0f051d;
}

.product-card__rating {
  font-size: 1rem;
  color: #ffcc00;
  margin-bottom: 0.5rem;
  text-align: justify;
}

.product-card__description {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
  color: #0f051d;
}

@media (max-width: 1000px) {
}
@media (max-width: 850px) {
  .interior-solutions {
    padding-left: 60px;
    padding-right: 60px;
  }
  .interior-solutions__title {
    font-size: 42px;
    line-height: 35px;
  }
}
@media (max-width: 500px) {
  .interior-solutions {
    padding-left: 20px;
    padding-right: 20px;
  }
  .interior-solutions__title {
    font-size: 32px;
  }
  .product-card {
    max-width: 150px;
  }
  .product-card__title {
    font-size: 16px;
  }
}
