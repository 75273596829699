.partners_playwood {
  background-color: #ffffff;
  padding-right: 120px;
  padding-left: 120px;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand_heading {
  margin-bottom: 20px; 
}

.brand_logos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10rem;
  width: 80%;
}

.Play_wood_our_brand {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 58.09px;
  text-align: center; 
  color: #000000;  
}

.playwood_partners__logo {
  width: 100%;
}

@media (max-width: 1000px) {
  .partners_playwood {
    padding-top: 0px;
  }
}
@media (max-width: 850px) {
  .Play_wood_our_brand {
    font-size: 38px;
  }
  .partners_playwood {
    background-color: #ffffff;
    padding-right: 60px;
    padding-left: 60px;
  }
  
}
@media (max-width: 500px) {
  .Play_wood_our_brand {
    font-size: 28px;
  }
  .partners_playwood {
    background-color: #ffffff;
    padding-right: 20px;
    padding-left: 20px;
  }
}
