.contact-form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.contact-form__image-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form__image {
  max-width: 100%;
  height: auto;
}

.contact-form__form-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.contact-form__title {
  font-size: 24px;
  font-weight: 600;
  color: #0f051d;
  margin-bottom: 20px;
  text-align: center; /* Center the title */
}

.contact-form__form {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.contact-form__input,
.contact-form__textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.contact-form__textarea {
  resize: vertical;
  min-height: 100px;
}

.contact-form__button {
  padding: 10px 20px;
  background: linear-gradient(83.65deg, #2600fc 0%, #ff00ea 94.96%);
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
  font-weight: 700;
}

.contact-form__button:hover {
  background: linear-gradient(to right, #2575fc, #6a11cb);
}

@media (max-width: 600px) {
  .contact-form {
    flex-direction: column; /* Stack the image and form vertically */
    padding: 10px; /* Reduce padding on mobile */
  }

  .contact-form__image-section {
    margin-bottom: 20px; /* Add some space between the image and form */
  }

  .contact-form__form {
    width: 100%; /* Ensure full width on mobile */
    max-width: 100%; /* Remove max-width restriction */
  }

  .contact-form__title {
    font-size: 20px; /* Slightly smaller title on mobile */
  }

  .contact-forminput,
  .contact-formtextarea {
    padding: 10px; /* Adjust padding on inputs */
    font-size: 14px; /* Smaller font size for inputs */
    margin-bottom: 10px; /* Adjust margin for spacing */
  }

  .contact-form__button {
    padding: 10px; /* Adjust button padding */
    font-size: 14px; /* Smaller button font size */
    width: 100%; /* Make button full width on mobile */
  }
}
