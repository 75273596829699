/* InteriorSection.css */

.interior-section {
  background-color: #ffffff;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 100px;
  padding-bottom: 50px;
}

.interior-section__title {
  font-family: "Inter", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  margin-bottom: 100px;
  color: #0f051d;
}

.interior-section__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  row-gap: 100px;
}

.product-card_in {
  background-color: #fff;
  border-radius: 1rem;
  padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 269px;
}

.product-card_in:hover {
  transform: translateY(-5px);
}

.product-card_in__image {
  width: 100%;
  height: auto;
  max-width: 150px;
  margin-bottom: 1rem;
}

.product-card_in__name {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #0f051d;
  margin-top: 30px;
}

.product-card_in__rating {
  color: #f39c12;
}

.product-card_in__description {
  font-size: 14px;
  color: rgba(15, 5, 29, 1);
  font-weight: 400;
}
.in_Product_bg {
  background-color: #a9ec78;
  display: flex;
  justify-content: center;
  height: 140px;
  position: relative;
  border-radius: 4px;
}
.product-card_in__image {
  position: absolute;
  top: -29%;
  right: 7%;
}
@media (max-width: 850px) {
  .interior-section {
    padding-left: 60px;
    padding-right: 60px;
  }
  .interior-section__title {
    font-size: 30px;
  }
}
@media (max-width:674px){
    .product-card_in {
        width: 213px;
      }
      
}
@media (max-width:564px){
    .product-card_in {
        width: 180px;
      }
      .product-card_ex__image {
        height: auto;
        max-width: 150px;
        margin-bottom: 1rem;
      }
      .ex_Product_bg {
        background-color: #A9EC78;
        display: flex;
        justify-content: center;
        height: 90px;
        position: relative;
        border-radius: 4px;
        /* margin-bottom: 40px; */
      }
}
@media (max-width: 500px) {
  .product-card_in {
    width: 100%;
  }
    .interior-section {
      padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
      }
      .interior-section__title {
        font-size: 24px;
        margin-bottom: 50px;
      }
      .product-card_in__name {
        font-size: 18px;
    }
}
@media (max-width: 416px) {
    .interior-section__list{
        row-gap: 50px;
    }
 
      .product-card_ex__image {
        height: auto;
        max-width: 150px;
        margin-bottom: 1rem;
      }
      .ex_Product_bg {
        background-color: #A9EC78;
        display: flex;
        justify-content: center;
        height: 100px;
        position: relative;
        border-radius: 4px;
        /* margin-bottom: 40px; */
      }
}