.play_wood_productsec_conatianer{
 padding-left: 120px;
 padding-right: 120px;
 padding-top: 50px;
 padding-bottom: 50px;
  }
  .products_sec{
    display: flex;
  }
  .productsec__title{
    font-family: "Inter", sans-serif;
    font-size: 52px;
    font-weight: 700;
    line-height: 64px;
    text-align: center;
    margin-bottom: 50px;
    color: #0F051D;

  }
  .productsec__subtitle{
    font-family: "Inter", sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #0F051D;
    padding-bottom: 30px;
  }
  .products__image {
    flex: 1;
    text-align: center;
  }
  
  .products__image-content {
    max-width: 100%;
    height: auto;
  }
  

  
  .products__highlight {
    color: #d4af37;
  }
  

  
  .products__list {
    list-style-type: disc;
    padding-left: 1.5rem;
  }
  
  .products__item {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #0F051D;
    margin-bottom: 30px;
  }
  
  .products__item strong {
    font-weight: 600;

    color: #0F051D;

  }
    
  /* @media (max-width: 768px) {
    .products_sec {
      flex-direction: column;
      text-align: center;
    }
  
    .products__content {
      padding-left: 0;
      text-align: center;
    }
  
    .products__image {
      margin-bottom: 2rem;
    }
  } */

  @media (max-width: 1000px) {
    .products_sec{
      flex-direction: column-reverse;
    }
    .productsec__subtitle{
      text-align: center;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 850px) {
    .play_wood_productsec_conatianer{
      padding-left: 60px;
      padding-right: 60px;
    }
    .productsec__title{
      font-size: 42px;
      margin-bottom: 30px;
    }
    .productsec__subtitle{
      font-size:28px;
    }
    .products__item {
      font-size: 18px;
    }
  }
  @media (max-width: 500px) {
    .play_wood_productsec_conatianer{
      padding-left: 20px;
      padding-right: 20px;
    }
    .productsec__title{
      font-size: 32px;
    }
    .productsec__subtitle{
      font-size: 24px;
    }
    .products__item {
      font-size: 14px;
    }
  }