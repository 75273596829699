.Product_range_container {
  padding-left: 120px;
  padding-right: 120px;
  text-align: center;
  background-color: #f9f9f9;
  height: auto;
  padding-bottom: 100px;
  padding-top: 50px;
  position: relative;
}

.product-range__title {
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
}

.product-range__title--highlight {
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.product-range__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  margin-top: 50px;
}

.product-range__item {
  width: 32%;
  margin-bottom: 40px;
  text-align: left;
}

.product-range__item--left {
  text-align: left;
}

.product-range__item--right {
  text-align: left;
}

.product-range__item-number {
  font-size: 19px;
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.product-range__item-title {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #0f051d;
}

.product-range__item-description {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color:#0F051D;  
}

.product-range__image {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.product-range__splatter {
  position: absolute;
  top: 10px;
  right: 20px;
  max-width: 50px;
}
.product-range__image img {
  max-width: 550px;
}

.drop1 {
  position: absolute;
  right: 20px;
  top: -12%;
}
.drop2 {
  position: absolute;
  left: 0;
  bottom: -15%;
}

.Second_2 {
  position: relative;
  left: -34%;
}
.Second_4 {
  position: relative;
  left: 7%;
  top: 11%;
}

.third_line {
  position: absolute;
  left: 7%;
  width: 23%;
  /* right: 10%; */
  top: 42%;
  margin-top: 50px;
}
@media (max-width: 1244px) {
  .product-range__image img {
    max-width: 500px;
  }
}
@media (max-width: 1175px) {
  .product-range__image img {
    max-width: 450px;
  }
}
@media (max-width: 1096px) {
  .product-range__image img {
    max-width: 350px;
  }
}
@media (max-width: 1000px) {
  .product-range__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 50px;
  }
  .product-range__image {
    margin-top: 140px;
    position:static;
    top:auto;
    left:auto;
    transform: translate(0%, 0%);
    overflow: hidden;
  }
  .product-range__image img {
    width: 100%;
  }
  .third_line {
    position: absolute;
    left:auto;
    width: auto;
    top: auto;
  }
  .Second_2 {
    position: static;
    left: auto;
  }
  .Second_4 {
    position: static;
    left: auto;
    top: auto;
  }
  .product-range__item {
    width: 100%;
    margin-bottom: 40px;
    text-align: left;
  }
  .product-range__item--left {
    text-align: left;
    height: fit-content;
    overflow: hidden;
  }
}
@media (max-width: 850px) {
  .Product_range_container {
    padding-left: 60px;
    padding-right: 60px;
  }
  .product-range__title {
    font-family: "Inter", sans-serif;
    font-size: 42px;
  }
}
@media (max-width: 500px) {
  .Product_range_container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .product-range__title {
    font-family: "Inter", sans-serif;
    font-size: 32px;
  }
  .product-range__item-title {
    font-size: 20px;
  }
  .product-range__item-description {
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }
  
.drop1 {
  position: absolute;
  right: 0px;
  top: -5%;
  width: 30%;
}
.drop2 {
  position: absolute;
  left: 0;
  bottom: 0%;
  width: 30%;
}

}