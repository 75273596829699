.footer {
   background-color: #f9f9f9;
   padding: 50px 0px;
   font-family: Arial, sans-serif;
   color: #333333;
   border-top: 1px solid rgba(131, 131, 131, 0.25);
 }
 
 .footer__content {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   /* border-bottom: 1px solid #e0e0e0; */
   padding-bottom: 2rem;
   margin-bottom: 1rem;
 }
 
 .footer__brand {
   flex: 1;
   max-width: 30%;
   margin-bottom: 1rem;
 }
 
 .footer__logo {
   max-width: 80px;
   margin-bottom: 0rem;
 }
 
 .footer__description {
   font-size: 17px;
   font-weight: 400;
   color: rgba(123, 117, 131, 1);
   margin-top: 0px;
 }
 
 .footer__social {
   display: flex;
   gap: 1rem;
 }
 
 .footer__social-link {
   color: #333333;
   font-size: 1.2rem;
   text-decoration: none;
 }
 
 .footer__links {
   flex: 2 1;
   display: flex;
   justify-content: flex-end;
   column-gap: 130px;
   margin-top: 12px;
 }
 
 .footer__column {
   max-width: 150px;
 }
 
 .footer__heading {
   font-weight: 700;
   font-size: 22px;
   color: rgba(15, 5, 29, 1);
   margin-bottom: 0.75rem;
 }
 
 .footer__list {
   list-style-type: none;
   padding: 0;
 }
 
 .footer__link {
   font-size: 17px;
   color: rgba(123, 117, 131, 1);
   font-weight: 400;
   text-decoration: none;
   margin-bottom: 0.5rem;
   display: block;
 }
 
 .footer__link:hover {
   color: #800080;
 }
 
 .footer__bottom {
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 0.8rem;
   color: #999999;
 }
 
 .footer__copyright {
   margin: 0;
 }
 
 .footer__policies {
   display: flex;
   gap: 1rem;
 }
 
 .footer__policy-link {
   color: #666666;
   text-decoration: none;
 }
 
 .footer__policy-link:hover {
   color: #800080;
 }
 
 /* Responsive styling */
 @media (max-width: 768px) {
   .footer__content {
     display: flex;
     flex-direction: column;
   }
   .footer__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.8rem;
      color: #999999;
      display: grid;
      /* grid-template-columns: auto auto; */
      row-gap: 10px;
  }
 
   .footer__links {
     order: 1; /* Make Quick Links and Services appear first */
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     width: 100%;
     margin-top: 0;
   }
 
   .footer__brand {
      order: 2;
      display:grid;
      flex-direction: column;
      align-items: center;
      text-align: left;
      max-width: 100%;
  }
 
   .footer__social {
     justify-content: center;
   }
 }
 