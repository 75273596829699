@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .floating-whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #ffffff;
    border-radius: 40%;
    padding: 15px;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    animation: pulse 1.5s infinite ease-in-out; /* Animation added */
    transition: transform 0.3s ease;
  }
  
  .floating-whatsapp-icon:hover {
    transform: scale(1.2);
  }
  