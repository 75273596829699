.welcome {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: hashtag#ffffff;
  padding-left: 120px;
  padding-right: 120px;
  height: 90vh;
}

.welcome__title {
  font-family: "Inter", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 77.45px;
  text-align: left;
}

.welcome__subtitle {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  text-align: left;
}

.welcome__image {
  max-width: 50%;
  text-align: right;
}

.welcome__image-content {
  max-width: 100%;
  height: auto;
}
@media (max-width: 1000px) {
  .welcome {
    flex-direction: column;
    align-items:center;
    justify-content: center;
  }
  .welcome__title {
    text-align: center;
  }
  .welcome__subtitle {
    text-align: center;
  }
}
@media (max-width: 850px) {
  .welcome {
    padding-left: 60px;
    padding-right: 60px;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .welcome__title {
    font-size: 45px;   
    line-height: 50.45px;
 
  }
  .welcome__subtitle {
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  .welcome {
    padding-left: 20px;
    padding-right: 20px;
  }
  .welcome__title {
    font-size: 35px;    
  }
  .welcome__subtitle {
    font-size: 18px;
  }
}
