/* Container Styles */
.gallery-container {
  text-align: center;
  padding: 2rem;
}

/* Buttons Section */
.gallery-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.gallery-button {
  background-color: #f0f0f0;
  border: 2px solid #ddd;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 8px;
}

.gallery-button.active {
  background: linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  color: white;
  border-color: #007bff;
  transform: translateY(-2px);
}

.gallery-button:hover {
  transform: translateY(-2px);
}

/* Gallery Grid Section */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 0 1rem;
}

.gallery-item {
  border: 2px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s;
}

.gallery-item img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.gallery-item:hover {
  transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 992px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .gallery-buttons {
    flex-wrap: wrap;
  }

  .gallery-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

@media (max-width: 576px) {
  .gallery-buttons {
    gap: 0.5rem;
  }

  .gallery-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
