/* OnTimeServices.css */

.on-time-services {
  text-align: left;
  position: relative;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 100px;
  padding-bottom: 50px;
}

.on-time-services__header {
  text-align: center;
  margin-bottom: 20px;
}

.on-time-services__title {
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #0f051d;
}

.on-time-services__title--highlight {
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.on-time-services__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.on-time-services__image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.on-time-services__image-file {
  max-width: 100%;
  height: auto;
}

.on-time-services__details {
  flex: 1.5;
}

.on-time-services__subtitle {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 58.8px;
  text-align: left;
  color: #0f051d;
  margin-bottom: 30px;
}

.on-time-services__list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.on-time-services__list-item {
  margin-bottom: 30px;
  color: #0f051d;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.on-time-services__list-icon {
  font-size: 16px;
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 10px;
}

.on-time-services__description {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #0f051d;
}
.gradBall {
  position: absolute;
  right: 0;
  bottom: 13%;
}

@media (max-width: 1000px) {
    
.on-time-services__content {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-top: 20px;
  }
  .on-time-services__subtitle {
    text-align: center;
  }
  .on-time-services__description {
    text-align: center;
  }
  .on-time-services__list-item {
    text-align: center;
  }
}

@media (max-width: 850px) {
  .on-time-services {
    padding-left: 60px;
    padding-right: 60px;
  }
  .on-time-services__title {
    font-size: 42px;
  }
  .on-time-services__subtitle {
    font-size: 30px;
    line-height: 38.8px;
  }
  .on-time-services__list-item {
    font-size: 18px;
  }
  .on-time-services__description {
    font-size: 18px;
  }
  .on-time-services__image{
    margin-top: 50px;
  }
}

@media (max-width: 500px) {
  .on-time-services {
    padding-left: 20px;
    padding-right: 20px;
  }
  .on-time-services__title {
    font-size: 32px;
  }
  .on-time-services__subtitle {
    font-size: 20px;
    line-height: 38.8px;
  }
  .on-time-services__list-item {
    font-size: 16px;
  }
  .on-time-services__description {
    font-size: 16px;
  }

}
