.faq {
  text-align: center;
  /* padding: 2rem; */
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  padding-top: 150px;
  padding-bottom: 50px;
}

.faq__title {
  font-size: 56px;
  font-weight: 700;
  /* margin-bottom: 2rem; */
  padding-bottom: 50px;
}

.faq__highlight {
  color: #800080;
}
.gradient {
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.faq__list {
  max-width: 700px;
  margin: 0 auto;
  padding: 50px;
  border: 1px solid #83838340;
  border-radius: 24px;
  transition: transform 0.3s ease;

}

.faq__item {
  /* background-color:#f9f9f9; */
  margin-bottom: 1rem;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  border-bottom: 1px solid #83838340 ;
  transition: background-color 0.3s ease;
  cursor: pointer;
  padding: 1rem;
}

.faq__item:hover {
  background-color: hashtag#f1f1f1;
}

.faq__item--open {
  background-color: hashtag#eeeeee;
}

.faq__question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: #0F051D;
  font-weight: 700;
}

.faq__icon {
  font-size: 1.25rem;
  color: #666666;
  transition: transform 0.3s ease;
}

.faq__icon--open {
  transform: rotate(180deg);
}

.faq__answer {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #7B7583;
  text-align: left;
}
@media (max-width: 850px) {
  .faq {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 100px;
  }
  .faq__title {
    padding-bottom: 20px;
  }

}
@media (max-width: 500px) {
  .faq {
    padding-left: 20px;
    padding-right: 20px;
  }
  .faq__list {
    padding: 20px;
  }
}
@media screen and (min-device-width: 200px) and (max-device-width: 768px) {

  .faq__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .faq__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #0F051D;
    font-weight: 700;
  }
  .faq__answer {
    margin-top: 1rem;
    font-size: 12px;
    color: #7B7583;
    text-align: left;
    font-weight: 400;
  }
}
