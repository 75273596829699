/* /* -----------------------------------------
   Navbar Component
----------------------------------------- */

/* Container */
/* .navbar { 
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    padding: 10px 20px; 
    background: linear-gradient(to right, #fff, #f0f0f0); 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    height:70px;
  } */
  
  /* Logo */
  .bg-body-tertiary{
   
  }
  .custom-navbar {
    background-color: #FFFFFF !important; 
    height: 100px;
    padding-left: 120px;
    padding-right: 120px;
}
  .navbar__logo { 
    display: flex; 
    align-items: center; 
   
  }
  .navbar__logo-image { 
    height: 120px; 
    width: 120px; 
  }
  
  /* Navigation */
  /* .navbar__nav { 
    flex-grow: 1; 
    display: flex; 
    justify-content: flex-end; 
  }
  .navbar__list { 
    display: flex; 
    gap: 20px; 
    list-style: none; 
    margin: 0; 
    padding: 0; 
  }
  .navbar__item { 
    display: flex; 
    align-items: center; 
  } */
  
  /* Links */
  .navbar__link { 
    text-decoration: none; 
    font-family: 'Inter', sans-serif !important; 
    font-weight: 700 !important; 
    font-size: 18px !important; 
    line-height: 20px;
    color: #0F051D !important; 
    /* transition: color 0.3s;  */
    margin-right:20px
  }
  .navbar__link:hover { 
    color: #2600FC!important; 
  }
  .navbar__link--active { 
    font-weight: 700; 
    color: #2600FC !important; 
  }
  /* nav{
    height:75px
  } */
   .navbar-collapse{
    background-color: #ffffff;
    padding-left: 20px;
   }
  @media (max-width: 1000px) {
    .navbar__logo-image {
      height: 90px;
      width: 90px;
  }
  }
  @media (max-width: 500px) {
    .custom-navbar {
      height: 80px;

  }
  .navbar {
    --bs-navbar-padding-y: 0px !important;
  }
  }
  @media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .navbar__logo-image {
      height: 69px;
      width: auto;
  }
    nav{
    height:100%
  }
 /* .navbar-toggler {
   
    border: none !important;
    font-weight: 900 !important;
    font-size: 26px !important;
} */
  }
 