.contact-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 120px;
  padding-right: 120px;
  background-color: #f8f8f8;
  position: relative;
  overflow: hidden;
  background-image: url("../Images/Paste image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 50vh;
}

.contact-section__content {
  z-index: 1;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.contact-section__title {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.4000000059604645px;
  text-align: center;
  color: #141718;
  margin-bottom: 10px;
}

.contact-section__subtitle {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
  color: #141718;
}

.contact-section__button {
  background: linear-gradient(90deg, #6a0dad, #ff1493);
  color: #fff;
  padding: 15px 30px; 
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.contact-section__button-icon {
  margin-left: 10px;
}

@media (max-width: 1000px) {

}
@media (max-width: 850px) {

  .contact-section {
    padding-left: 60px;
    padding-right: 60px;
  }
  .contact-section__title {
    font-size: 30px;
  }
}
@media (max-width: 500px) {
  .contact-section__title {
    font-size: 25px;
  }
  .contact-section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .contact-section__subtitle {
    font-size: 16px;
  }
}