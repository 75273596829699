.why-choose-us {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  padding-top: 50px;
  padding-left: 120px;
  padding-right: 120px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.why-choose-us__title {
  font-size: 52px;
  font-weight: 700;
  color: #0f051d;
  /* margin-bottom: 2rem; */
  padding-bottom: 50px;
  text-align: center;
}
.gradient {
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.why-choose-us__highlight {
  color: #800080;
}

.why-choose-us__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  /* margin-top: 50px; */
}

.why-choose-us__item {
  width: 230px;
  height: 400px;
  padding: 1rem;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  border: 1px solid #83838340;
  
  border-radius: 24px;
  margin-bottom: 2rem;
  background-color: #ffffff;
  transition: transform 0.3s ease;
}
.why-choose-us__item_2 {
  margin-top: 100px;
}
.why-choose-us__item_4 {
  margin-top: 60px;
}


.why-choose-us__item:hover {
  transform: translateY(-10px);
}

.why-choose-us__heading {
  font-size: 28px;
  color: #0f051d;
  margin-bottom: 1rem;
  font-family: 700;
}

.why-choose-us__description {
  font-size: 16px;
  color: #7b7583;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.why-choose-us__icon {
  margin-bottom: 1rem;
}

.why-choose-us__icon--expertise {
  background: url("../Images/Icon.png") no-repeat;
  background-size: contain;
  height: 50px;
}

.why-choose-us__icon--quality {
  background: url("../Images/Network Solutions.png") no-repeat;
  background-size: contain;
  height: 50px;
}

.why-choose-us__icon--service {
  background: url("../Images/Vector.png") no-repeat;
  background-size: contain;
  height: 50px;
}

.why-choose-us__icon--solutions {
  background: url("../Images/Badge.png") no-repeat;
  background-size: contain;
  height: 50px;
}


@media (max-width: 1220px) {
  .why-choose-us__item {
    width: 200px; 
  }
  .why-choose-us__heading{
    font-size: 24px;
  }
}
@media (max-width: 1101px) {
  .why-choose-us__item {
    width: 170px; 
  }
  .why-choose-us__heading{
    font-size: 20px;
  }
}
@media (max-width: 1000px) {
  .why-choose-us__title {
    margin-top: 50px;

  }
  .why-choose-us__list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* margin-top: 50px; */
  }
  .why-choose-us__item {
    width: 100%;
    height: 250px;
  }
  .why-choose-us__item_2 {
    margin-top: 0px;
  }
  .why-choose-us__item_4 {
    margin-top: 0px;
  }
}

@media (max-width: 850px) {
  .why-choose-us {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 560px) {
  .why-choose-us__item {
    width: 100%;
    height: 260px;
  }
}
@media (max-width: 500px) {
  .why-choose-us {
    padding-left: 20px;
    padding-right: 20px;
  }
  .why-choose-us__item {
    width: 100%;
    height: auto;
  }
}


@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
  .why-choose-us__title {
    font-size: 24px;
    font-weight: 700;
    /* margin-bottom: 2rem; */

    text-align: center;
  }
  .why-choose-us__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: 47% 47%;
  }
  .why-choose-us__item {
    width: 100%;
    padding: 1rem;
    border-radius: 12px;
    margin-bottom: 2rem;
    background-color: #ffffff;
    transition: transform 0.3s ease;
  }
  .why-choose-us__item_2 {
    margin-top: 0;
  }
  .why-choose-us__item_4 {
    margin-top: 0;
  }
  .why-choose-us__heading {
    font-size: 14px;
    margin-bottom: 1rem;
    font-family: 700;
    font-weight: 600;
  }
  .why-choose-us__description {
    font-size: 12px;
    margin-bottom: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
}


