.about-us {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    padding-left: 120px;
    padding-right: 120px;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.about-us__container {
    display: flex;
    align-items: center;
}

.about-us__image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us__image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.about-us__content {
    flex: 1;
}

.about-us__title {
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #0F051D;
}

.about-us__subtitle {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #0F051D;
}

.about-us__description {
    font-size: 19px;
    color: #7B7583;
    line-height: 1.6;
    margin-bottom: 16px;
    font-weight: 400;
}
@keyframes moveBall {
    0% {
        left: 100px;
        margin-top: -200px;
    }
    50% {
        left: 200px; /* Change to desired position */
        margin-top: -100px; /* Change to desired vertical position */
    }
    100% {
        left: 100px; /* Return to original position */
        margin-top: -200px;
    }
}
@keyframes moveBall2 {
    0% {
        right: 50px;
        margin-top: 100px;
    }
    40% {
        right: 80px;
        margin-top: 150px;
    }
    60% {
        right: 100px;
        margin-top: 100px;
    }
    100%{
        right: 50px;
        margin-top: 100px;
    }
}

.ball1 {
    position: absolute;
    left: 100px;
    margin-top: -200px;
    z-index: 1;
    animation: moveBall 4s ease-in-out infinite; /* 4 seconds, ease-in-out, infinite loop */
}
.ball2 {
    position: absolute;
    right:50px;
    margin-top: 100px;
    z-index: 1;
    animation: moveBall2 4s ease-in-out infinite; /* 4 seconds, ease-in-out, infinite loop */
}
@media (max-width: 1000px) {
    .about-us {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;

    }
    .about-us__container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 
            "content"
            "image";
    }

    .about-us__content {
        grid-area: content;
    }

    .about-us__image-container {
        grid-area: image;
    }

}
@media (max-width: 850px) {
    .about-us {       
        padding-left: 60px;
        padding-right: 60px;

    }
}
@media screen and (max-width: 768px) {

   
    .about-us__title {
        font-size: 20px;
    }

    .about-us__subtitle {
        font-size: 16px;
    }
    .ball1 {
        position: absolute;
        left: 9%;
        z-index: 1;
        top: 69%;
        /* bottom: 30% !important; */
    }
    .ball2 {
        position: absolute;
        right: 2%;
        bottom: 9%;
        z-index: 1;
    }
    .ball-abt {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 20%;
        display: none;
    }
    .ball-abtr {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 33%;
        right: 0;
        display: none;
    }
    .about-us__description {
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 16px;
        font-weight: 400;
    }
    .ball-abt2 {
        width: 16px;
        height: 16px;
        position: absolute;
        bottom: 27%;
        left: 6%;
    }
    .ball-abtrb {
        width: 16px;
        height: 16px;
        position: absolute;
        bottom: 6%;
        right: 10%;
    }
}
@media (max-width: 500px) {
    .about-us {
        margin-top: -50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ball1 {
        position: absolute;
        left: 9%;
        z-index: 1;
        top: 80%;
        /* bottom: 30% !important; */
    }
    .ball2 {
        position: absolute;
        right: 2%;
        bottom: 9%;
        z-index: 1;
    }
}