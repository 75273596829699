.projects {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 50px;
    padding-left: 120px;
    padding-right: 120px;
  }
  
  .projects__title {
    font-family: "Inter", sans-serif;
    font-size: 52px;
font-weight: 700;
line-height: 64px;
text-align: center;
margin-bottom: 80px;
  }
  
  .projects__title--highlight {
    background: -webkit-linear-gradient(70.73deg, #2600FC 0%, #FF00EA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .projects__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  
  .projects__item {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .projects__image {
    width: 100%;
    height: auto;
    /* display: block; */
  }
  @media (max-width: 1000px) {
  }
  @media (max-width: 850px) {
    .projects {
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 50px;
    }
    .projects__title {
      font-size: 42px;
      margin-bottom: 40px;
    }
  }
  @media (max-width: 500px) {
    .projects {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
    }
    .projects__title {
      font-size: 32px;
    }
  }