    .statistics {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 120px;
    padding-right: 120px;
    background-color: #FFFFFF; 
    position: relative !important;
    }

    .statistics__item {
    text-align: center;
    border-right: 1px solid rgb(240, 234, 234);
    }
    .last {
    border: 0;
    }
    .statistics__number {
    font-family: "Inter", sans-serif;
    font-size: 71px;
    font-weight: 700;
    color: #0F051D;
    }

    .statistics__label {
    font-family: "Inter", sans-serif;
    font-size: 17px;
    font-weight: 400;
    color:#7B7583;
    margin-top: 10px;
    }

    .button-statistics {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 25%;
    }
    @media (max-width: 1000px) {
    .statistics__number {
        font-size: 51px;
    }
    }
    @media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .statistics__number {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }

    .statistics__label {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: 400;
        color: rgba(123, 117, 131, 1);
        margin-top: 10px;
    }
    .statistics {
        display: grid;
        grid-template-columns: auto auto auto auto;
        padding-left:20px;
        padding-right: 20px;
        background-color: g #f8f8f8;
        position: relative !important;
    }
    }
