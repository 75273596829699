.contact-us {
  text-align: center;
  padding-top: 150px;
  padding-bottom: 150px;
  font-family: Arial, sans-serif;
  background: url("../Images/bg-contact.png") center center/cover no-repeat;
  color: #0f051d;
  position: relative;
}

.contact-us__title {
  font-size: 96px;
  font-weight: 700;
  font-weight: bold;
  margin-bottom: 1rem;
}
.gradient {
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact-us__subtitle {
  font-size: 22px;
  color: rgba(123, 117, 131, 1);
  font-weight: 400;
  margin-bottom: 2rem;
}   

.contact-us__button {
  background-color: #800080;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  background: linear-gradient(58.08deg, #2600fc 0%, #ff00ea 100%);
}

.contact-us__button:hover {
  background-color: #5e005e;
}

.contact-us__button-icon {
  margin-left: 0.5rem;
  font-size: 1.2rem;
}
.circle {
  position: absolute;
  z-index: 1;
  left: 20%;
}
.btn2 {
  position: absolute;
  z-index: 1;
  right: 20%;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
  .contact-us {
    text-align: center;
    /* padding: 61px 0px; */
    font-family: Arial, sans-serif;
    background: url("../Images/bg-contact.png") center center/cover no-repeat;
    color: #0f051d;
    position: relative;
    height: 400px;
  }
  .footer__copyright {
    margin: 0;
    font-size: 12px;
  }
  .footer__policies {
    display: flex;
    gap: 1rem;
    font-size: 15px;
  }

  .contact-us__title {
    font-size: 24px;
    font-weight: 700;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .contact-us__subtitle {
    font-size: 16px;
    color: rgba(123, 117, 131, 1);
    font-weight: 400;
    margin-bottom: 2rem;
  }
  .contact-us__button-icon {
    margin-left: 0.5rem;
    font-size: 18px;
  }
  .btn2 {
    position: absolute;
    z-index: 1;
    right: 20%;
    top: -5%;
  }
  .circle {
    position: absolute;
    z-index: 1;
    left: 20%;
    top: -9%;
  }
  .cnt-txt {
    display: inline-block;
  }
}
.cnt-txt {
  display: block;
}
