/* PaintConsultations.css */

.paint-consultations {
  padding: 20px;
  text-align: left;
  position: relative;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 100px;
  padding-bottom: 50px;
}

.paint-consultations__header-icon {
  display: flex;
  align-items: center;
}

.paint-consultations__header-icon-dot {
  border-radius: 50%;
  margin-right: 5px;
}

.paint-consultations__header-icon-dot--small {
  width: 8px;
  height: 8px;
  background-color: #bb86fc;
}

.paint-consultations__header-icon-dot--large {
  width: 12px;
  height: 12px;
  background-color: #9a67ea;
}

.paint-consultations__title {
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  margin-bottom: 20px;
}

.paint-consultations__title--highlight {
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paint-consultations__content {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.paint-consultations__text {
  flex: 1;
  margin-right: 20px;
}

.paint-consultations__subtitle {
  font-family: "Inter", sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 58.8px;
  color: #0f051d;
  text-align: left;
}

.paint-consultations__tagline {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #0f051d;
}

.paint-consultations__description {
  font-family: "Inter", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #7b7583;
}

.paint-consultations__image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paint-consultations__image-file {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
.balls1 {
  position: absolute;
  left: 0%;
  top: 10%;
   animation: moveBall 4s ease-in-out infinite;
}
.balls2 {
  position: absolute;
  left: 20px;
  animation: moveBall2 4s ease-in-out infinite;
}

.balls3 {
  position: absolute;
  right: 20px;
  top: 50%;
  animation: moveBall2 4s ease-in-out infinite;
  
}
@media (max-width: 1000px) {
  .paint-consultations__content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
  }
  .paint-consultations__title {
    text-align: center;
    margin-bottom: 50px;
  }
  .paint-consultations__subtitle {
    text-align: center;
    margin-bottom: 20px;
  }
  .paint-consultations__tagline {
    text-align: center;
    margin-bottom: 30px;
  }
  .paint-consultations__description {
    text-align: center;
    margin-bottom: 50px;
  }
}
@media (max-width: 850px) {
  .paint-consultations {
    padding-left: 60px;
    padding-right: 60px;
  }
  .paint-consultations__title {
    font-size: 42px;
    margin-bottom: 30px;
  }
  .paint-consultations__subtitle {
    font-size: 36px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 30px;
  }
  .paint-consultations__tagline {
    font-size: 22px;
  }
  .paint-consultations__description {
    text-align: center;
    margin-bottom: 20px;
  }
}
@media (max-width: 500px) {
  .paint-consultations {
    padding-left: 20px;
    padding-right: 20px;
  }
  .paint-consultations__title {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .paint-consultations__subtitle {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .paint-consultations__tagline {
    font-size: 20px;
  }
  .paint-consultations__description {
    font-size: 16px;
    line-height: 21px;
  }
}
