.background-section {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust the height based on your needs */
    background-image: url('../Images/Rectangle.png');
    background-size: cover;
    background-position: center;
  }
  
  .background-section__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 223, 128, 0.7); /* Yellow overlay with transparency */
    background: linear-gradient(0deg, #FFE68C, #FFE68C),
linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 14.84%, rgba(255, 255, 255, 0) 89.65%, #FFFFFF 100.03%);
opacity: 20%;
  }