.contact {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  background-image: url("../Images/bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f8f8f8;
  text-align: center;
  padding-left: 120px;
  padding-right: 120px;

}

.contact__content {
  max-width: 600px;
  padding: 2rem;
  border-radius: 8px;
}

.contact__title {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.4000000059604645px;
  text-align: center;
  color: #141718;
}

.contact__subtitle {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: #141718;
}

.contact__button {
  background: linear-gradient(
    90deg,
    rgba(195, 149, 68, 1) 0%,
    rgba(255, 230, 140, 1) 100%
  );
  color: #ffffff;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.contact__button:hover {
  background-color: #c99e32;
}

.contact__button-icon {
  margin-left: 0.5rem;
  font-size: 1.2rem;
}

@media (max-width: 1000px) {

}
@media (max-width: 850px) {
  .contact {
    padding-left: 60px;
    padding-right: 60px;
  }
  .contact__title {
    font-size: 30px;
  }
}
@media (max-width: 500px) {
  .contact {
    padding-left: 20px;
    padding-right: 20px;
  }
  .contact__title {
    font-size: 25px;
  }
  
.contact__subtitle {
  font-size: 16px;
}
}