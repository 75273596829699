.product-section {
  text-align: center;
  /* padding: 2rem; */
  margin-top: 100px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 50px;
}

.product-section__title {
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
}

.product-section__title--highlight {
  color: #a64cc4; /* Highlight color */
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.product-section__subtitle {
    font-family: "Inter", sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    margin-bottom: 80px;
    margin-top: 200px;

}

.product-section__list {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.product-card_waterproof {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.product-card_waterproof:hover {
  transform: translateY(-5px);
}

.product-card_waterproof__image {
  height: 100%;
  object-fit: contain;
}

.product-card_waterproof__name {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #0F051D;
    
}

.product-card_waterproof__rating {
  color: #f39c12; /* Star color */
  margin-bottom: 1rem;
  text-align: left;
}

.product-card_waterproof__description {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #0F051D;    
}



.bg-colors-green {
  background-color: rgba(92, 213, 107, 1);
  height: 200px;
  display: flex;
  align-items:center;
  justify-content:flex-end;
  border-radius: 8px;

}
.bg-colors-green img {
  height: 150%;
  margin-bottom: 100px;
}
.image-bg-colos {
  margin-top: 102px;
}
@media (max-width: 1125px) {
    
    .bg-colors-green img {
        height: 110%;
        margin-bottom: 70px;
    }
}
@media (max-width: 1000px) {
    .product-section__title {
        font-family: "Inter", sans-serif;
        font-size:42px;
    }
    .bg-colors-green img {
        height: 150%;
        margin-bottom: 70px;
    }
    .product-section__list {
        
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 2rem;
      }
}

@media (max-width: 850px) {
    .product-section {
        padding-left: 60px;
        padding-right: 60px;
      }
      
    .product-section__subtitle {
        font-size: 30px;    
        margin-top: 20px;
    }
    .bg-colors-green img {
        height: 150%;
        margin-bottom: 70px;
    }
    .product-section__list {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 2rem;
      }
}
@media (max-width: 500px) {
    .product-section {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 10px;

      }
      .product-section__title {
        font-family: "Inter", sans-serif;
        font-size: 32px;
    }
      .product-section__subtitle {
        font-size: 24px;    
    }
    }