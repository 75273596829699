.gallery-carousel {
  text-align: center;
  /* padding: 2rem; */
  background-color: #fff;
  /* padding: 50px 0px; */
  /* height: auto + 100px; */
  padding-top: 50px;
}

.gallery-carousel__title {
  font-size: 52px;
  font-weight: 700;
  /* margin-bottom: 1rem; */
  color: #0f051d;
}

.gallery-carousel__title--highlight {
  color: #a945ea; /* Customize the color for "gallery" */
}

.slick-track {
  margin-top: 30px;
  margin-bottom: 15px;
}
.gallery-carousel__image {
  padding: 0.5rem;
  transition: transform 0.3s ease;
}

.gallery-carousel__image img {
  width: 100%;
  border-radius: 12px;
  height: 250px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}

.gallery-carousel .gallery-carousel__image:hover {
  transform: scale(1.2);
}

.slick-dots {
  /* bottom: -30px; */
}

.slick-dots li button:before {
  /* font-size: 52px !important; */
  color: #a945ea; /* Color for the active dot */
}

.slick-dots li.slick-active button:before {
  color: #a945ea;
}

.slick-dots li button:before {
}

.slick-dots li button:before {
  font-family: "slick";

  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li button:before:hover {
  background: linear-gradient(180deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.slick-dots li.slick-active button:before {
  background: linear-gradient(180deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.slick-dots li.slick:hover button:before {
  background: linear-gradient(180deg, #2600fc 0%, #ff00ea 100%) !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.slick-dots li button:before:hover {
  background: linear-gradient(180deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* General style for the button */
.slick-dots li button:before {
  content: "";
  font-size: 10px !important;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, gray 0%, gray 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Hover state */
.slick-dots li button:hover:before {
  font-size: 20px !important;
  background: linear-gradient(180deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Active state */
.slick-dots li.slick-active button:before {
  font-size: 20px !important;
  background: linear-gradient(180deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1000px) {
  /* .gallery-carousel__title {
    margin-top: 50px;
  } */
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
  .gallery-carousel__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }
  

  .slick-track {
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

/* Ensure the dots are displayed */
.slick-dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  list-style: none;
  display: flex !important;
  justify-content: center;
}

.slick-dots li button:before {
  color: #fff; /* You can change the dot color here */
}

.slick-dots li.slick-active button:before {
  color: #ff0000; /* Color for active dot */
}
