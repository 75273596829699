.play_wood_hero_section {
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
}
.wood {
  position: absolute;
  top: 200px;
  z-index: 1;
  left: 0;
  height: 204px;
}
.wood2 {
  position: absolute;
  top: 108px;
  z-index: 2;
  right: 0;
  height: 204px;
  /* transform: rotatey(150deg); */
}
.wood3 {
  position: absolute;
  top: 700px;
  z-index: 2;
  left: 0;
  height: 204px;
}

.innovation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 3rem 2rem; */
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}

.innovation__content {
  flex: 1;
  padding-right: 2rem;
  text-align: left;
}

.innovation__title {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.73px;
  text-align: center;
  color: #000000;
}

.innovation__subtitle {
  font-family: "Inter", sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 24.2px;
text-align: center;
color: #000000;
}

.innovation__image {
  max-width: 100%;
  height: auto;
}

.innovation__quote-form {
  flex: 1 1;
  /* background-color: #f8f8f8; */
  padding: 2rem;
  border-radius: 8px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
  display: flex;
  justify-content: center;
}

.innovation__form-title {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #0F051D;
  
}

.innovation__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.innovation__input,
.innovation__textarea {
  /* width: 300px; */
  padding: 0.75rem;
  border: 1px solid #C5BFC6;
  border-radius: 4px;
  font-size: 1rem;
  color:#C5BFC6;
}

.innovation__textarea {
  resize: vertical;
}

.innovation__submit {
  background: linear-gradient(58.08deg, #c39544 0%, #ffe68c 100%);
  /* width:300px; */
  /* background-color: #d4af37; */
  color: #ffffff;
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 300px;
  font-weight: 700;
}

.innovation__submit:hover {
  background-color: #c99e32;
}
@media (max-width: 1000px) {
  .innovation {
   flex-direction: column;
  }
  .innovation__subtitle {
    margin-bottom: 50px;
  }
  .innovation__form-title {
    text-align: center;
  }
}
@media (max-width: 850px) {
  .play_wood_hero_section {
    padding-left: 60px;
    padding-right: 60px;
  }
  .innovation__title {
    font-size: 28px;
  }
  .innovation__subtitle {
    font-size: 18px;
  }
  .innovation__form-title {
    font-size: 22px;
  }
  .wood {
    height: 150px;
    top: 300px;

  }
  .wood2 {
    height: 150px;
    top: 50px;
  }
  .wood3 {
    height: 150px;
    
  }

}

@media (max-width: 500px) {
  .play_wood_hero_section {
    padding-left: 20px;
    padding-right: 20px;
  }

.innovation__title {
  font-size: 24px;
}
.innovation__subtitle {
  font-size: 16px;
}
.innovation__form-title {
  font-size: 20px;
}
}

.text-center {
  text-align: center;
}

.banner1 {
  position: relative;
}
.Img2nd {
  position: absolute;
  left: 65px;
  top: 65px;
}

.innovation__title {
  padding: 0px 100px;
}
.container {
  padding: 50px;
}
::placeholder {
  color: rgb(197 191 198);
}
