.hero {
  /* background-image: url("../Images/bgPaint.png"); */
    background-image: url("../Images/New/main.jpg");

  /* background-color: #7b3fe4; */
  background-size: cover;
  background-position: center;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-y: hidden;
  padding-left: 120px;
  padding-right: 120px;
  position: relative;
  overflow: hidden; /* Ensure the curve is contained within the element */
}

.hero__title {
  font-size: 64px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 77.45px;
  text-align: center;
  color: white;
  margin-top: 45%;
}

.hero__title--highlight {
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero__description {
  margin-top: 30px;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: center;
}


@media (max-width: 1150px) {
  .hero__title {
    font-size: 54px;
  }
}
@media (max-width: 1000px) {
  .hero__title {
    font-size: 44px;
    line-height: 57.45px;
  }
  .hero__description {
  font-size: 20px;
  }
}
@media (max-width: 850px) {
  .hero {
    padding-left: 60px;
    padding-right: 60px;
  }
  .hero__description {
    font-size: 18px;
    }
}
@media (max-width: 500px) {
  .hero {
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .hero__title {
    font-size: 22px;
    line-height: 22.45px;
  }
  .hero__description {
    font-size: 16px;
    line-height: 22.36px;

    }
}