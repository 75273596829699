.commitment {
  background-color: #ffffff;
  text-align: center;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.commitment__title {
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #0f051d;
  margin-bottom: 50px;
}

.commitment__highlight {
  color: #d4af37;
}

.commitment__grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
}

.commitment-item {
  max-width: 255px;
  /* padding: 1rem; */
  text-align: center;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease;
}

.commitment-item:hover {
  transform: translateY(-5px);
}

.commitment-item__image {
  max-width: 250px;
  margin-bottom: 1rem;
}

.commitment-item__title {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #0f051d;
}

.commitment-item__description {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #0f051d;
}

@media (max-width: 1000px) {
}
@media (max-width: 850px) {
  .commitment {
    padding-left: 60px;
    padding-right: 60px;
  }
  .commitment__title {
    font-size: 42px;
  }
  .commitment-item__title {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .commitment__grid {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .commitment {
    padding-left: 20px;
    padding-right: 20px;
  }
  .commitment__title {
    font-size: 32px;
    line-height: 40px;
  }
  .commitment-item__title {
    font-size: 18px;
  }
  .commitment-item__description {
    font-size: 14px;
  }
}

