.advice-support {
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #ffffff;
  text-align: center;
  position: relative;
}
.wood4 {
  position: absolute;
  /* top:108px; */
  z-index: 2;
  right: 0;
  height: 204px;
  /* transform: rotatey(150deg); */
}
.wood5 {
  position: absolute;
  /* top:108px; */
  bottom: -100px;
  z-index: 2;
  left: 0;
  height: 204px;
  /* transform: rotatey(150deg); */
}

.advice-support__title {
  font-family: "Inter", sans-serif;
font-size: 52px;
font-weight: 700;
line-height: 64px;
text-align: center;
margin-bottom: 30px;

}

.advice-support__highlight {
  color: #d4af37;
}

.advice-support__grid {
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  gap: 2rem;
}

.advice-support-item {
  max-width: 293px;
  padding: 0;
  text-align: center;
  transition: transform 0.3s ease;
}

.advice-support-item:hover {
  transform: translateY(-5px);
}

.advice-support-item__image {
  margin-bottom: 1rem;
  width: 100%;
  height: 182px;
  object-fit: fill;
}

.advice-support-item__title {
  font-family: "Inter", sans-serif;
font-size: 24px;
font-weight: 700;
line-height: 30px;
text-align: center;
color: #0F051D;
  margin-bottom: 0.5rem;
}

.advice-support-item__description {
  font-family: "Inter", sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 20px;
text-align: center;
color: #0F051D;
}

/* @media (max-width: 768px) {
  .advice-support__grid {
    flex-direction: column;
    align-items: center;
  }
} */
@media (max-width: 1000px) {

}
@media (max-width: 850px) {
  .advice-support {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .advice-support__grid {
    flex-direction: column;
    align-items: center;
  }
  .advice-support-item__title {
    font-size: 20px;
  }
  .advice-support__title {
    font-size: 42px;
  }
}
@media (max-width: 500px) {
  .advice-support {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
.advice-support-item__title {
  font-size: 18px;
}
.advice-support-item__description {
  font-family: "Inter", sans-serif;
font-size: 14px;
}
.advice-support__title {
  font-size: 32px;
}
.wood4 {
  margin-top: 50px;
  height: 150px;
}
.wood5 {
  height: 150px;
}
}