.cursor {
  position: fixed;
  pointer-events: none;
  display: flex;
  align-items:center ;
  justify-content: center;
  color: White;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  z-index: 9999999;
}

.cursor-border {
  top: -25px;
  left: -25px;
  width: 50px;
  height: 50px;
  border: 2px solid #3d3d3d;
  border-radius: 50%;
  transition: all 0.4s cubic-bezier(0, 0, 0.14, 0.98);
  z-index: 9999999;
}
.corseractive {
  top: -45px;
  left: -45px;
  width: 90px;
  height: 90px;
  background-color: #3d3d3d;
  mix-blend-mode: difference;
  transition: all 0.1s linear;
  border-radius: 50%;
  z-index: 9999999;
} 
.cursor-dot {
  top: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  background-color: #3d3d3d;
  border-radius: 50%;
  z-index: 9999999;
}


