.paintbrands {
  padding-left: 120px;
  padding-right: 120px;
  text-align: center;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 100px;
}

.partnerss__title {
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  color: #000000;
  font-family: "Inter", sans-serif;
}

.partners__image img {
  max-width: 100%;
}
.partners__logos-container {
  overflow: hidden;
}
.asian_paint_conatiner_1 {
  display: flex;
  gap: 40px;
}
.asian_paint {
  max-width: 100%;
}
@media (max-width: 1000px) {

  .partnerss__title {
    font-size: 38px;
  }
}
@media (max-width: 850px) {
  .paintbrands {
    padding-left: 60px;
    padding-right: 60px;
  }
  .partnerss__title {
    font-size: 28px;
  }
}
@media (max-width: 500px) {
  .asian_paint_conatiner_1 {
    gap: 5px;
  }
  .paintbrands {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
  }
  .partnerss__title {
    font-size: 20px;
  }
}