.services {
  text-align: left;
  font-family: Arial, sans-serif;
  padding-left: 120px;
  padding-right: 120px;
  position: relative;
}

.services__title {
  font-size: 52px;
  font-weight: 700;
  color: #0f051d;
  margin-bottom: 2rem;
  text-align: center;
}
.gradient-number {
  font-size: 19px;
  font-weight: 700;
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient {
  color: #0f051d;
  background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.services__sub_heading {
  font-size: 24px;
  color: #0f051d;
  margin-bottom: 1rem;
  font-weight: 700;
  margin-top: 0px;
}

.services__list {
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.services__item {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
.mt-20 {
  margin-top: 20px;
}
.services__image {
  flex: 1;
  max-width: 50%;
  height: auto;
}

.services__content {
  flex: 1;
  padding-left: 2rem;
}

.services__heading {
  font-size: 56px;
  color: #0f051d;
  margin-bottom: 1rem;
  font-weight: 700;
  margin-top: 10px;
}

.services__description {
  font-size: 19px;
  color: #7b7583;
  margin-top: 30px;
  font-weight: 400;
}

.services__link {
  font-size: 17px;
  color: #0f051d;
  text-decoration: none;
  margin-top: 3rem;
  font-weight: 700;
}

.services__link:hover {
  border-bottom: 1px solid rgba(131, 131, 131, 0.25);
  padding-bottom: 5px;
}
.link_service {
  margin-top: 60px;
}
.ball-abt {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20%;
  animation: moveBall 4s ease-in-out infinite;
}
.ball-abtr {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 33%;
  right: 20px;
  animation: moveBall2 4s ease-in-out infinite;
}
.ball-abtrb {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 1%;
  right: 50px;
  animation: moveBall 4s ease-in-out infinite;
}
.ball-abt2 {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 25%;
  left: 6%;
  animation: moveBall2 4s ease-in-out infinite;
}
@media (max-width: 1000px) {
  .services__item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "content"
      "image";
    align-items: center;
    justify-content: center;
  }

  .services__content {
    grid-area: content;
    padding-left: 0;
    text-align: center;
  }

  .services__image {
    grid-area: image;
    max-width: 100%;
    height: auto;
  }

}
@media (max-width: 850px) {
  .services {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 500px) {
  .services {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
  .services__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0px;
  }
  .gradient-number {
    font-size: 16px;
    font-weight: 700;
    background: -webkit-linear-gradient(70.73deg, #2600fc 0%, #ff00ea 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .services__heading {
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
  }
  .services__sub_heading {
    font-size: 16px;
    font-weight: 600;
    margin-top: 0px;
  }
  .services__item {
    display: grid;
    align-items: center;
    margin-top: 50px;
    justify-content: center;
  }
  .services__image {
    flex: 1 1;
    max-width: 100%;
    height: auto;
  }
  .services__description {
    font-size: 16px;
  
    font-weight: 400;
  }
  .services__link {
    font-size: 14px;
  }
  .ball-abtrb {
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 6%;
    right: 30px;
  }
}

@media screen and (max-width: 768px) {
 

  .services__heading {
    font-size: 20px;
   
  }

  .services__sub_heading {
    font-size: 16px;
  }

  .services__description {
    font-size: 16px;
      margin-top: 0px;
  }

  .services__link {
    font-size: 14px;
  }
  .link_service {
    margin-top: 30px;
  }
} 
