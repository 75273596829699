/* -----------------------------------------
   Hero Section Component
----------------------------------------- */

/* Container */
.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 120px;
  padding-right: 120px;
  background: url("../Images/bg-1.png") no-repeat center center/cover;
  background-size: cover; /* Ensures the background image covers the entire section */
  height: 80vh;
  overflow: hidden;
}

/* .hero-section__content {
    max-width: 50%;
  } */

.hero-section__title {
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 77.45px;
  color: #000000;
}

.hero-section__subtitle {
  text-align: left;
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  color: #000000;
}

/* .hero-section__image {
    max-width: 50%;
  } */

.hero-section__image-img {
  width: 100%;
  height: auto;
  display: block;
  margin-left: 20px;
}

@media (max-width: 1150px) {
  .hero-section__title {
    font-size: 54px;
  }
}

@media (max-width: 1000px) {
  .hero-section {
    flex-direction: column;
  }

  .hero-section__title {
    font-size: 44px;
    text-align: center;
  }
  .hero-section__subtitle {
    text-align: center;
  }
  .hero-section__image-img {
    width: 450px;
    height: 300px;
  }
}

@media (max-width: 850px) {
  .hero-section {
    padding-left: 60px;
    padding-right: 60px;
  }
  .hero-section__title {
    font-size: 45px;
    text-align: center;
    line-height: 50.45px;
  }
  .hero-section__subtitle {
    text-align: center;
    font-size: 18px;
  }
}
@media (max-width: 500px) {
  .hero-section {
    justify-content: start;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    margin-bottom: 20px;
    height: auto;

  }
  .hero-section__title {  
    font-size: 22px;
    text-align: center;
    line-height: 20.45px;
  }
  .hero-section__subtitle {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;

  }
  .hero-section__image-img {
    max-width: 100%;
    height: auto;
    margin-left: 0px;
  }
}