/* WoodFinishesSection.css */

.wood-finishes-section {
  /* text-align: center; */
  /* padding: 2rem; */
  background-color: #f9f9f9;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 100px;
  padding-bottom: 50px;
}
.bgGray {
  background-color: #f9f9f9;
}
.wood-finishes-section__title {
  font-family: "Inter", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  margin-bottom: 50px;
}

.wood-finishes-section__list {
  display: flex;
  gap: 2rem;
}

.wood-card_finish {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease;
  /* width: 280px; */
  display: grid;
  grid-template-columns: 50% 50%;
}
.wood-card_finish:hover {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: grid;
  grid-template-columns: 50% 50%;
}
.wood-card_finish__sample {
}

.wood-card_finish:hover {
  transform: translateY(-5px);
}

.wood-card_finish__image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.wood-card_finish__name {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #0f051d;
}

.wood-card_finish__rating {
  color: #f39c12;
  margin-bottom: 1rem;
}

.wood-card_finish__description {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #0f051d;
}

.wood-card_finish__sample {
  display: grid;
  justify-content: space-around;
  gap: 0.5rem;
}

.wood-card_finish__sample-image {
  width: 100%;
  height: 100%;
}
.bg-lightCss {
  background-color: rgba(204, 189, 183, 1);
  padding: 50px;
  border-radius: 10px;
}

@media (max-width: 1000px) {
  .wood-finishes-section__list {
    flex-direction: column;
  }
  .wood_card_text_conatainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 850px) {
  .wood-finishes-section__title {
    font-size: 30px;
  }
  .wood-finishes-section {
    padding-left: 60px;
    padding-right: 60px;
  }

}
@media (max-width: 850px) {
  .wood-finishes-section__title {
    font-size: 24px;
  }
  .wood-finishes-section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .wood-card_finish {
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
  }
  .bg-lightCss {
    padding: 20px;
  }
.wood-card_finish__name {
font-size: 16px;
}
.wood-card_finish__description {
  font-size: 12px;
}
}