  .image-slider {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #f8f9fa;
  }

  .image-slider__title {
    font-family: "Inter", sans-serif;
    font-size: 52px;
    font-weight: 700;
    line-height: 64px;
    text-align: center;
    color: #0F051D;
    margin-bottom: 80px;
  }

  .image-slider__title--main {
    color: #2c2c2c;
  }

  .image-slider__title--highlight {
    color: #d4af37;
  }

  .image-slider__subtitle {
    font-family: "Inter", sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
  }

  .image-slider__description {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    margin-bottom: 50px;
  }

  .image-slider__carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .image-slider__carousel-control {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
  }

  .image-slider__carousel-images {
    display: flex;
    overflow-x: auto;
    gap: 1rem;
  }

  .image-slider__image {
    flex: 0 0 20%;
    border-radius: 8px;
    overflow: hidden;
  }

  .image-slider__image img {
    width: 100%;
    height: auto;
    display: block;
  }

  .image-slider__carousel-control--prev,
  .image-slider__carousel-control--next {
    font-size: 2rem;
    color: #333;
  }

  .image-slider__carousel-control--prev:hover,
  .image-slider__carousel-control--next:hover {
    color: #d4af37;
  }

  
/* Hover state */
.image-slider .slick-dots li button:hover:before {
  font-size: 20px !important;
  background:  #d4af37;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Active state */
.image-slider .slick-dots li.slick-active button:before {
  font-size: 20px !important;
  background:  #d4af37;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
  @media (max-width: 1000px) {

  }
  @media (max-width: 850px) {
    .image-slider {
      padding-left: 60px;
      padding-right: 60px;
    }
    .image-slider__title {
      font-size: 42px;
      margin-bottom: 30px;
    }
    
  .image-slider__subtitle {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .image-slider__description {
    font-size: 18px;
  }
    
  }
  @media (max-width: 500px) {
    .image-slider {
      padding-left: 20px;
      padding-right: 20px;
    }
    .image-slider__title {
      font-size: 32px;
      margin-bottom: 20px;
    }
    .image-slider__subtitle {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .image-slider__description {
      font-size: 16px;
    }
  }